import * as React from "react";
import { SeoComponent } from "../components/atoms";
import { CoverComponent } from "../components/molecules";
import {
  HeaderComponent,
  FooterComponent,
  SectionComponent,
} from "../components/organisms";

// markup
const IndexPage = () => {
  return (
    <>
      <HeaderComponent />
      <CoverComponent />
      <SectionComponent id="business" title="事業内容">
        <>
          <div>
            <h3>システム開発事業</h3>
            <p>
              Webを中心としたシステムの設計・開発・運用・保守を行っています。
              <br />
              御社のビジネスを担うシステムの開発チームに参加させて頂いて、設計・開発・運用・保守をお手伝い致します。
              <br />
              主に、Ruby on Railsを用いたWebアプリケーションの設計・開発・保守、
              Go言語を用いたGraphQL、gRPCのマイクロサービスの設計・開発・保守などを行っています。
              また、Contentful、Netlify、Next.jsなどを利用したいわゆるJamstackなWebサイトの構築・運用も行っています。
              <br />
            </p>
          </div>
        </>
      </SectionComponent>
      <SectionComponent id="company" title="会社概要">
        <>
          <table>
            <tbody>
              <tr>
                <td>会社名</td>
                <td>HANAYA Tech合同会社</td>
              </tr>
              <tr>
                <td>所在地</td>
                <td>神奈川県川崎市高津区溝口1-9-7長谷川ビル5F</td>
              </tr>
              <tr>
                <td>設立年月</td>
                <td>2022年10月</td>
              </tr>
              <tr>
                <td>代表者</td>
                <td>代表社員 島涼平</td>
              </tr>
            </tbody>
          </table>
          <h3>代表経歴</h3>
          <p>
            1978年、長野県生まれ。
            <br />
            2002年、電気通信大学を卒業後、システム開発企業に就職し、Webシステム、通信系システム、POSシステム等、種々のシステム開発を担当。
            <br />
            2019年、フリーランスのエンジニアとして独立。Webシステムの設計・開発・運用・保守に従事。
            <br />
            2022年、法人化しHANAYA Tech合同会社を設立。
          </p>
        </>
      </SectionComponent>
      <SectionComponent id="contact" title="お問い合わせ">
        <p>
          お仕事のご依頼、弊社に関するご質問などございましたら、
          <a href="https://forms.gle/J941e51mjcbfr5e76" target="_blank">
            こちらのフォーム
          </a>
          からお願いします。
        </p>
      </SectionComponent>
      <FooterComponent />
    </>
  );
};

export default IndexPage;
export const Head = () => <SeoComponent />;
